function Overview() {
  return (
    <div className="container mx-auto px-4 py-4">
      <p className="text-5xl md:text-7xl font-blod">学习记录</p>
      <div className="flex flex-row space-x-4 text-sm font-bold leading-6 mt-5">
        <div className="basis-1/2 h-14 flex items-center justify-center">
          <div className="shadow-lg bg-fuchsia-500 rounded-lg w-32 md:w-64 h-full">
            <button className="w-full h-full">当前</button>
          </div>
        </div>
        <div className="basis-1/2 h-14 flex items-center justify-center">
          <div className="shadow-lg bg-fuchsia-500 rounded-lg w-32 md:w-64 h-full">
            <button className="w-full h-full">往期</button>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Overview;
