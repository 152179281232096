import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Overview from './Episodes/Overview';
import HomeworkList from './Episodes/homework-list'
import reportWebVitals from './reportWebVitals';
import WithRouter from './withRouter';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const HomeworkListWithRouter = WithRouter(HomeworkList)
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="overview" element={<Overview />} />
      <Route path="homework-list/:id" element={<HomeworkListWithRouter />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
