function getMediaComponent(homeworkItem) {
    if (homeworkItem.file) {
        let file = homeworkItem.file
        const audiofileExtend = ".mp3"
        const videofileExtend = ".mp4"
        const fileExtend = file.substring(file.lastIndexOf(".")).toLowerCase()
        if (videofileExtend.indexOf(fileExtend) >= 0) {
            const poster = file + "?x-oss-process=video/snapshot,t_7000,f_jpg,m_fast"
            return (
                <video controls controlsList="nodownload" className='mb-4 rounded-xl' poster={poster}>
                    <source src={file} type="video/mp4" />
                </video>)
        } else if (audiofileExtend.indexOf(fileExtend) >= 0) {
            return (
                <audio controls controlsList="nodownload" className='mb-4 rounded-xl'>
                    <source src={file} />
                </audio>)
        } else {
            return <div />
        }
    }

    if (homeworkItem.bookId) {
        return (
            <button type="button" onClick={() => navigateToBookPage(homeworkItem.bookId)} className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out">
                看书
            </button>)
    }

    return <div />
}

function navigateToBookPage(bookId) {

    fetch(`https://api.enjoyonline.cn/app/wx/books/${bookId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data && data.code === 0) {
                const book = data.data
                let url = "../../packageLibrary/book/main?"
                url = url + "storagePath=" + book.storagePath + "&totalPageNumber=" + book.totalPageNumber
                window.wx.miniProgram.navigateTo({ url })
            }
        })
}

export const HomeworkCard = (props) => (
    <div className={`block p-6 rounded-xl shadow-lg ${props.backgroundStyle}`}>
        <p className="text-gray-700 text-base mb-4">
            {props.homeworkItem.content}
        </p>
        {getMediaComponent(props.homeworkItem)}
    </div>
);
