//import { lesson } from '../__mocks__/lesson';
import { HomeworkCard } from './homework-card'
import { HomeworkListToolbar } from './homework-list-toolbar'
import { useEffect, useState } from 'react'

const backgroundColors = ['to-purple-100', 'to-indigo-100', 'to-pink-100', 'to-orange-100']

function navigateToHuanke(lessonId) {
  let url = "../returnLesson/main?"
  url = url + "lessonId=" + lessonId
  window.wx.miniProgram.navigateTo({
    url: url,
    success: function () {
      console.log('success')
    },
    fail: function () {
      console.log('fail')
    },
    complete: function () {
      console.log('complete')
    }

  })
}

const HomeworkList = (props) => {
  const [episode, setEpisode] = useState({ lessons: [] })

  useEffect(() => {
    fetch(`https://api.enjoyonline.cn/app/teaching/lessons/${props.router.params.id}`)
      .then((res) => res.json())
      .then((data) => {
        const currentEpisode = { lessons: data.data.courseList }
        setEpisode(currentEpisode)
      });
  }, [props.router.params.id])

  useEffect(() => {
    document.title = "课后复习"
  }, [])

  return (
    <div className='py-8 px-4'>

      {episode.lessons.map((lesson, lessonIndex) => {
        return (
          <div key={lessonIndex}>
            <HomeworkListToolbar lesson={lesson} />
            <div className='flex flex-row flex-wrap justify-center lg:justify-between items-center pt-3'>
              {lesson.homeworkList.map((homeworkItem, index) => (
                <div className='my-2 lg:max-w-sm w-full' key={homeworkItem.id}>
                  <HomeworkCard homeworkItem={homeworkItem} backgroundStyle={`bg-gradient-to-r from-transparent ${backgroundColors[index % 4]}`} />
                </div>
              ))}
            </div>
            <hr className="my-12" />
          </div>
        )
      })}
      <button type="button" onClick={() => navigateToHuanke(props.router.params.id)} className="inline-block h-12 w-24 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">还课</button>
    </div>
  )
}

export default HomeworkList
