//import logo from './logo.svg';
import './App.css';
import { Link } from "react-router-dom";

function App() {
  return (
    <div>
      <h1 className="text-3xl font-bold underline">Bookkeeper</h1>
      <Link to="/overview">Overview</Link>
      <Link to="/homework-list/2312">HomeworkList</Link>
    </div>
  );
}

export default App;
