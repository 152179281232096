export const HomeworkListToolbar = ({ lesson }) => (
  <div>
    <div className='flex align-center justify-between'>
      <div>
        <h4 className="font-medium leading-tight text-2xl break-all">{lesson.title}</h4>
        <h6 className="font-medium leading-tight text-base text-gray-500">{lesson.orderNo * 2 - 1}-{lesson.orderNo * 2}H</h6>
      </div>
      {/* <div className="flex space-x-2 justify-center">
        <button type="button" onClick={() => navigateToHuanke(lesson.id)} className="inline-block h-12 w-24 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">还课</button>
      </div> */}
    </div>
  </div>
);
